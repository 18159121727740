import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import * as aboutStyles from "../components/about.module.css"
import { scale } from "../utils/typography"

const title = "O nas";

const section1_title = "Czym jest SAT?";
const section1_content = "Studenckie Koło Naukowe Silesian Aerospace Technologies \
                          narodziło się w 2019 roku z pragnienia o\xa0eksploracji kosmosu. \
                          W tym momencie zrzeszamy około dwadzieścia osób o\xa0różnych \
                          umiejętnościach i\xa0zainteresowaniach, a nawet studiujących \
                          na różnych uczelniach.  Jesteśmy młodym, ambitnym i zgranym \
                          zespołem udowadniającym, że sky is not the limit!";

const section2_title = "Czym się zajmujemy?";
const section2_content = "Zajmujemy się szeroko pojętym near-space technology. \
                          Budujemy balony stratosferyczne, rakiety, projektujemy eksperymenty \
                          naukowe, dysponujemy stacją naziemną pozwalającą na \
                          odbieranie satelit na niskiej orbicie ziemskiej. \
                          Naszym wielkim marzeniem jest zbudować CubeSata. \
                          Pokazujemy, że branża kosmiczna w\xa0Polsce ma przyszłość!";

const section3_title = "Jak się zapisać?";
const section3_content = "Chciałbyś dołączyć do Naszego koła? Nic prostszego! \
                          Odezwij się do nas na facebooku albo napisz do nas maila \
                          na skn.sat.polsl@gmail.com ;)";

const About = () => {
  const data = useStaticQuery(graphql`
  query {
    background: file(relativePath: {eq: "about-background.png"}) {
      childImageSharp {
        fluid(maxWidth: 2546, quality: 95) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    logo: file(relativePath: {eq: "sat-logo-horizontal-white.png"}) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 95) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    about_photo: file(relativePath: {eq: "about.png"}) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 95) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
  `)

  return (
  <Layout>
    <SEO title="O nas" />
    <div className={aboutStyles.aboutContainer}>
      <Img 
        className={aboutStyles.backgroundImage}
        fluid={data.background.childImageSharp.fluid} 
      />
      <Img 
        className={aboutStyles.logo}
        fluid={data.logo.childImageSharp.fluid} 
      />
      <div className={aboutStyles.background}>
        <div className={aboutStyles.inner}>
          <h1 style={{
            ...scale(2),
            margin: `1rem 0`,
          }}>
            {title}
          </h1>
        </div>

        <div className={aboutStyles.leftLine} />
        <div className={aboutStyles.inner}>
          <h1>{section1_title}</h1>
          <p>{section1_content}</p>
        </div>
        <div className={aboutStyles.rightLine} />
        <div className={aboutStyles.inner}>
          <h1 style={{textAlign:`right`}} >{section2_title}</h1>
          <p style={{textAlign:`right`}}>{section2_content}</p>
        </div>
        <div className={aboutStyles.leftLine} />
        <div className={aboutStyles.inner}>
          <h1>{section3_title}</h1>
          <p>{section3_content}</p>
        </div>
        <div className={aboutStyles.inner}>
          <Img fluid={data.about_photo.childImageSharp.fluid} />
        </div>
      </div>
    </div>
  </Layout>
  )
}

export default About
