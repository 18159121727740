// extracted by mini-css-extract-plugin
export var blackLine = "footer-module--blackLine--817c0";
export var chairmanTitle = "footer-module--chairmanTitle--00c81";
export var contactBackground = "footer-module--contactBackground--9e0a8";
export var contactDataTitle = "footer-module--contactDataTitle--82f97";
export var contactTitle = "footer-module--contactTitle--8b54f";
export var contanctBackground = "footer-module--contanctBackground--4dded";
export var facebookLink = "footer-module--facebookLink--69a5b";
export var innerPartners = "footer-module--innerPartners--d1528";
export var leftLine = "footer-module--leftLine--1aeaf";
export var leftSide = "footer-module--leftSide--55b0d";
export var leftText = "footer-module--leftText--83e10";
export var partnersBackground = "footer-module--partnersBackground--d3b72";
export var rightLine = "footer-module--rightLine--012f2";
export var rightSide = "footer-module--rightSide--6c412";
export var rightText = "footer-module--rightText--0068d";
export var supervisorTitle = "footer-module--supervisorTitle--429de";
export var whiteLine = "footer-module--whiteLine--91d8c";